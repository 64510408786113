
import { defineComponent } from 'vue';
import settings, { CookieSettings, PageSettings } from "./settings-mixin";

export default defineComponent({
  mixins: [settings],
	data(){
		return {
			justSent: false,	 
		}
	},
  methods: {
    loadSettings() {
      //
    },
    saveSettings() {
      //
    }
  },
  watch: {
    pageSettings: {
			deep:true,
			handler(newValue: PageSettings) {
				localStorage.setItem("pageSettings", JSON.stringify(this.pageSettings));
				// if (JSON.stringify(newValue) == JSON.stringify(oldValue)) return;
				if(this.justSent){
					this.justSent = false;
					return;
				}
				this.justSent = true;
				window.dispatchEvent(new CustomEvent("updatedSettings"));
			}
    },
    cookieSettings(newValue: CookieSettings) {
      localStorage.setItem("cookieSettings", JSON.stringify(this.cookieSettings));
      // if (JSON.stringify(newValue) == JSON.stringify(oldValue)) return;
      // window.dispatchEvent(new CustomEvent("updatedSettings"));
    }
  },
})
