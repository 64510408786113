
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorDisplay",
  data() {
    return {
      visible: false,
      message: "Generic Error",
      code: 500,
      dismissEvent: this.dismissEventFunction,
    };
  },
  methods: {
    showError(_error: ErrorDetails) {
      this.message = _error.message || "Error";
      this.code = _error.code || 500;
      this.dismissEvent = _error.dismissEvent;
      this.visible = true;
    },
    dismiss() {
      this.visible = false;
      if (this.dismissEvent) {
        this.dismissEvent();
      }
    },
		dismissEventFunction(){
			return;
		}
  },
  mounted() {
    window.addEventListener("displayError", (error: any) => {
      this.showError(error.detail);
		});
  },
});

interface ErrorDetails {
	message: string,
	code: number,
	dismissEvent: ()=>void;
}

// interface ErrorEvent {
// 	detail: ErrorDetails;
// }

