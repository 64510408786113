
import { defineComponent } from "vue";

export default defineComponent({
  name: "Header",
  data() {
    return {
      
    };
  },
	computed: {
		loggedIn() {
			let id = localStorage.getItem("id");
			if(id) return true;
			return false;
		}
	},
});
