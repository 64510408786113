
import { defineComponent } from "vue";
import ErrorDisplay from "./components/ErrorDisplay.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import settings from "./components/settings-mixin";
import AnnouncementBannerWrapper from "./components/announcement/AnnouncementBannerWrapper.vue";

export default defineComponent({
  mixins: [settings],
  components: {
    ErrorDisplay,
    Header,
    Footer,
		AnnouncementBannerWrapper,
  },
  beforeMount() {
    this.checkSettings();
    console.log("before Mount: App", this.pageSettings);
    this.checkDarkmode();
    window.addEventListener("settingsUpdated", this.checkDarkmode);
  },
  methods: {
    checkDarkmode() {
      if (this.pageSettings.darkMode) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    },
  }
});
