import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "collapsible" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["collapsible-headline", {'collapsible-open': _ctx.open}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, _toDisplayString(_ctx.headline), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["collapsible-content", {'collapsible-visible': _ctx.open}])
    }, _toDisplayString(_ctx.content), 3)
  ]))
}