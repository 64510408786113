
import { defineComponent } from 'vue';
import Settings from "./Settings.vue";

export default defineComponent({
	components: {
		Settings
	},
	data(){
		return {
			settingsVisible: false
		}
	},
	methods: {
		toggleSettings(){
			this.settingsVisible = !this.settingsVisible;
		}
	}
})
