
import { defineComponent } from 'vue';

export default defineComponent({
	name: "Collapsible",
	props: ["headline","content"],
	data(){
		return {
			open: false
		}
	},
	methods: {
		toggle() {
			this.open = !this.open;
		}
	}
})
