
import { defineComponent } from 'vue';

export default defineComponent({
	props: ["content"],
	methods: {
		close() {
			this.$emit("close");
		},
	}
})
