import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnnouncementBanner = _resolveComponent("AnnouncementBanner")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showAnnouncementBanner)
      ? (_openBlock(), _createBlock(_component_AnnouncementBanner, {
          key: 0,
          onClose: _ctx.closeAnnouncementBanner,
          content: _ctx.announcementContent
        }, null, 8, ["onClose", "content"]))
      : _createCommentVNode("", true)
  ]))
}